import "./team.scss";

import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import yellowCircle from "../../../assets/vectors/yellow-circle.svg";

const Team: React.FC = () => {
  return (
    <div className={"o-team"}>
      <div className={"container"}>
        <img
          src={yellowCircle}
          className={"a-circle-rotate a-circle-rotate--center"}
          alt={"Círculo"}
        />
        <h2 className={"a-line-center"}>El equipo AlmaySer</h2>
      </div>
      <div className={"container grid-x3 grid-x3--large-gap "}>
        <div className={"m-block"}>
          <div className={"a-image-block"}>
            <StaticImage
              src={"../../../assets/images/maria.jpg"}
              alt={"María Casanovas"}
              width={600}
              height={600}
              className={"is-sepia"}
            />
          </div>
          <div className={"m-text"}>
            <h3 className={"a-position"}>María Casanovas</h3>
            <p>BFRP Practitioner acreditada por el Bach Centre de Inglaterra</p>
          </div>
        </div>
        <div className={"m-block m-block--to-bottom"}>
          <div className={"a-image-block"}>
            <StaticImage
              src={"../../../assets/images/connieflint.jpeg"}
              alt={"Practitioner"}
              width={600}
              height={600}
              className={"is-sepia"}
            />
          </div>
          <div className={"m-text"}>
            <h3 className={"a-position"}>Connie Flint</h3>
            <p>BFRP Practitioner acreditada por el Bach Centre de Inglaterra</p>
          </div>
        </div>
        <div className={"m-block"}>
          <div className={"a-image-block"}>
            <StaticImage
              src={"../../../assets/images/instagram.png"}
              alt={"Practitioner"}
              width={600}
              height={600}
            />
          </div>
          <div className={"m-text"}>
            <h3 className={"a-position"}>Practitioner</h3>
            <p>Próximamente le daremos la bienvenida.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
