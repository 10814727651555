import "./philosophy.scss";

import * as React from "react";

import greenCircle from "../../../assets/vectors/green-circle.svg";
import yellowCircle from "../../../assets/vectors/yellow-circle.svg";

const Philosophy: React.FC = () => {
  return (
    <section className={"o-philosophy"}>
      <div className={"container"}>
        <p className={"a-line-center"}>
          &quot;Usted no puede esperar construir un mundo mejor sin mejorar a
          las personas. Cada uno de nosotros debe trabajar para su propia
          mejora.&quot;
        </p>
        <p className={"a-intro-author"}>Marie Curie (1867-1934).</p>
        <section>
          <h2 className="a-title-heading">Mí filosofía</h2>
          <div
            className={"m-blocks grid-x2 grid-x2--break-pro grid-x2--no-gap"}
          >
            <div className={"m-blocks__item"}>
              <img
                src={yellowCircle}
                className={"a-circle-rotate"}
                alt={"Círculo"}
              />
              <p className={"a-paragraph"}>
                <strong>De todo se aprende.</strong> Todo lo que nos pasa en la
                vida es por alguna razón y por eso lo mejor que podemos hacer es
                tomar consciencia y aprender. A veces nos llegan situaciones que
                nos generan malestar, sin embargo, si tomamos consciencia de la
                lección que se esconde detrás, el dolor se convierte en
                aprendizaje, y nos descubrimos superando otro obstáculo.
              </p>
            </div>
            <div className={"m-blocks__item"}>
              <img
                src={yellowCircle}
                className={"a-circle-rotate"}
                alt={"Círculo"}
              />
              <p className={"a-paragraph"}>
                <strong>La emoción cómo indicador vital.</strong> Muchas veces
                ignoramos nuestras emociones o incluso las intentamos reprimir,
                pero en realidad, las emociones son un indicador de hacia dónde
                debemos movernos, nos dan pistas de donde estamos mal y de donde
                estamos bien. Las emociones nos señalan las heridas y las
                virtudes para que podamos trabajar en ellas, así que vamos a
                honrarlas y a aprender de ellas.
              </p>
            </div>
            <div className={"m-blocks__item m-blocks__item--right"}>
              <img
                src={greenCircle}
                className={"a-circle-rotate"}
                alt={"Círculo"}
              />
              <p className={"a-paragraph"}>
                <strong>El poder de conocerse.</strong> Todas las emociones
                provienen de las dos fuentes principales: o del amor o del
                miedo. Por eso es tan importante el proceso de autoconocimiento,
                para analizarnos, conocernos profundamente y saber en qué
                emoción vibramos. Cuánto más nos conocemos, más poder personal
                desarrollamos.
              </p>
            </div>
            <div className={"m-blocks__item m-blocks__item--right"}>
              <img
                src={greenCircle}
                className={"a-circle-rotate"}
                alt={"Círculo"}
              />
              <p className={"a-paragraph"}>
                <strong>Con calma y atención a las señales.</strong> He
                aprendido que a veces fluir no es avanzar, que la pausa es
                necesaria, y que cuando paramos a tomar aliento tenemos la
                oportunidad de rectificar nuestro rumbo. Una canción decía que
                cuando nos parece que caminamos hacia atrás, no estamos
                retrocediendo, solo estamos cogiendo carrerilla. Fluir es
                aceptar todos los ritmos e intensidades de la vida.
              </p>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Philosophy;
