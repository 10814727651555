import * as React from "react";
import { PropsWithChildren } from "react";
import Helmet from "react-helmet";

interface PropsWhoWheAre extends PropsWithChildren<any> {
  author: any;
}

const WhoWheAreMarkupSchema: React.FC<PropsWhoWheAre> = (
  props: PropsWhoWheAre
) => {
  const { author } = props;
  const markup = `
{
  "@context": "https://schema.org/",
  "@type": "Person",
  "name": "${author.name}",
  "image": "${author.image.asset.gatsbyImageData.images.fallback.src}",
  "sameAs": [
      "${author.socials.instagram}",
      "${author.socials.facebook}",
      "${author.socials.tiktok}"
    ],
  "jobTitle": "BFRP Practitioner"  
}
`;
  return (
    <Helmet>
      <script type="application/ld+json">{markup}</script>
    </Helmet>
  );
};

export default WhoWheAreMarkupSchema;
