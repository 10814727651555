import React from "react";

import Layout from "../components/common/layout/layout";
// import Brands from "../views/shared/Brands/Brands";
import Hero from "../views/shared/Hero/Hero";
// import Reviews from "../components/Reviews/Reviews";
import Philosophy from "../views/about-me/Philosophy/Philosophy";
import Team from "../views/about-me/Team/Team";
import SEO from "../components/common/SEO/SEO";
import {StaticImage} from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";
import WhoWheAreMarkupSchema from "../components/common/SEO/WhoWeAreMarkupSchema";

const HomePage = () => {
  const { allSanityAuthor: authors } = useStaticQuery(graphql`
        query allAuthors {
            allSanityAuthor {
                nodes {
                    name
                    image {
                        _key
                        _type
                        asset {
                            _id
                            gatsbyImageData
                        }
                    }
                    socials {
                        tiktok
                        instagram
                        facebook
                    }
                }
            }
        }
  `);
  const whoWheAreSchemas_render = [];
  authors.nodes.forEach((author, index)=>{
    whoWheAreSchemas_render.push(<WhoWheAreMarkupSchema author={author} key={index} />);
  })

  return (
    <Layout>
      {whoWheAreSchemas_render}
      <SEO
        title="El equipo AlmaySer. Conócenos"
        description="En AlmaySer somos un equipo de terapeutas florales homologadas que podemos ayudarte con tu bienestar emocional. Descubre nuestros servicios."
        canonical={"sobre-mi"}
      />
      <Hero title={"Maria Casanovas"} position={"Fundadora AlmaySer"} is_home={false} description={["Soy María y es un placer que estés aquí. La vida nos acerca y nos aleja de las cosas por algún motivo, y que las Flores de Bach llegaran a mi, no fue casualidad. Lo sé porque desde que me acompañan me han ayudado tanto que me cuesta poder expresarlo en pocas palabras. He comprobado su eficacia y experimentado sus beneficios, por eso decidí formarme como Practitioner y poder dar a conocer las Flores de Bach para que ayuden a muchas más personas. \n" +
      "Desde entonces he acompañado a personas, animales y plantas y en todos ellos he visto florecer su mejor versión. ",
      "Pide cita online y empieza a florecer."]} cta_text={"Consulta online ahora"} alt={"Sobre mí - María Casanovas"}>
        <StaticImage src={"../assets/images/mirandoalmar.jpg"} alt={"Maria Casanovas"} width={960} height={659} className={"is-sepia"}/>
      </Hero>
      {/*<Brands />*/}
      <Philosophy />
      <Team />
      {/*<Reviews />*/}
    </Layout>
  );
};

export default HomePage;
